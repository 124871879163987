.App {
  text-align: center;
  background:black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.text{
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index:100;
  font-size:72px;
  font-weight:700;
  animation: textFade 10s infinite; /* Animation for fading */
  text-shadow: rgba(0, 0, 0, 0.267) 0px 0px 20px;
  color:white;
}

.smalltext{
  font-size:24px;
  font-weight:500;
  color:white;
}

@keyframes textFade {
  0%,20%, 80%, 100% {
      opacity:0;
  }
  50% {
      opacity:1;
  }

}

@keyframes fadeToRed {
  0%, 100% {
      background-color: rgba(255, 0, 0, 0); /* Transparent background */
      filter:blur(0px);
  }
  50% {
      background-color: rgba(255, 0, 0, 0.8); /* Slightly red background */
      filter:blur(10px);

  }
}

.App-header {
  background-image: url("blade.jpg"); /* Your background image */
  background-color: rgba(255, 0, 0, 0); /* Initial background color, transparent red */
  background-blend-mode: multiply; /* Blends the background color with the image */
  width: 100vw; /* Ensure the div takes full viewport width */
  height: 100vh; /* Ensure the div takes full viewport height */
  overflow: hidden; /* Ensures nothing spills out of the div */  animation: fadeToRed 10s infinite; /* Animation for fading */
  background-size:cover; /* Covers the full area of the element */
  background-position: center; /* Centers the background image */
  background-attachment:fixed; /* Keeps the background fixed during scrolling */
  color:white;
  
}

.joinButton{
  border:3px solid white;
  color:white;
  font-size:18px;
  font-weight:700;
  padding:10px;
  cursor:pointer;
  margin-top:30px;
  transition: 0.2s;
  text-decoration:none;
}

.joinButton:hover{
  background-color:white;
  color:black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo{
  border:5px solid white;
  border-radius:50%;
}


.getStarted{
  width:100vw;
  height:100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}